import {FIND_TALENT_APPLICATION, UPDATE_APPLICATION_FLOW} from "../action-types";
import {call, put, takeEvery} from 'redux-saga/effects'
import {findTalentApplicationSuccess, updateApplicationFlowSuccess} from "../actions/JobOffer";
import {fetchError, fetchExpired, fetchStart, fetchSuccess} from "../actions/Common";
import {findTalentApplicationRequest, updateJobApplicationFlowRequest} from "../api/JobOfferApplication";

function* findTalentApplication({token, talentID}){
    try {
        const response = yield call(findTalentApplicationRequest, token, talentID)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(findTalentApplicationSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}
function* updateApplicationFlow({token, formData}){
    try {
        yield put(fetchStart())
        const response = yield call(updateJobApplicationFlowRequest, token, formData)
        if (response.status === 200) {
            yield put(updateApplicationFlowSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}
export default function* jobOfferApplicationSaga() {
    yield takeEvery(FIND_TALENT_APPLICATION, findTalentApplication)
    yield takeEvery(UPDATE_APPLICATION_FLOW, updateApplicationFlow)
}