// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {
    GET_PORTAGE,
    GET_PORTAGE_SUCCESS,
    STORE_PORTAGE,
    STORE_PORTAGE_SUCCESS,
    CHECK_COMPANY_PORTAGE,
    CHECK_COMPANY_PORTAGE_SUCCESS
} from '../action-types'

// Import all api's
import {getPortagesRequest, storePortageRequest, checkPortageRequest} from '../api/Portage'

import {getPortagesSuccess, storePortagesSuccess, checkCompanyPortagesSuccess} from '../actions/Portage'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

function* getPortages({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(getPortagesRequest, token, id)
        if (response.status === 200) {
            yield put(getPortagesSuccess(response.data?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* storePortage({token, values}) {
    try {
        yield put(fetchStart())
        console.log(values)
        const response = yield call(storePortageRequest, token, values)
        if (response.status === 200) {
            yield put(storePortagesSuccess(response.data?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* checkCompanyPortage({token}) {
    try {
        yield put(fetchStart())
        const response = yield call(checkPortageRequest, token)
        if (response.status === 200) {
            yield put(checkCompanyPortagesSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_PORTAGE, getPortages)
    yield takeEvery(STORE_PORTAGE, storePortage)
    yield takeEvery(CHECK_COMPANY_PORTAGE, checkCompanyPortage)
}