import {
    DELETE_FILE,
    DELETE_FILE_SUCCESS,
    GET_ALL_UPLOAD_FILES,
    GET_ALL_UPLOAD_FILES_SUCCESS,
    SET_FILE_STATE,
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_EXPENSE_REPORT,
    UPLOAD_FILE_EXPENSE_REPORT_SUCCESS,
    UPLOAD_FILE_CONTRACTOR_INVOICE_SUCCESS
} from '../action-types'

export const getAllUploadFiles = (token) => {
    return {
        type: GET_ALL_UPLOAD_FILES,
        token: token,
    }
}

export const getAllUploadFilesSuccess = (data) => {
    return {
        type: GET_ALL_UPLOAD_FILES_SUCCESS,
        data: data,
    }
}

export const uploadFile = (token, values) => {
    return {
        type: UPLOAD_FILE,
        token: token,
        values: values,
    }
}

export const uploadFileSuccess = (data) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        data: data,
    }
}

export const uploadFileExpenseReport = (token, values) => {
    return {
        type: UPLOAD_FILE_EXPENSE_REPORT,
        token: token,
        values: values,
    }
}

export const uploadFileExpenseReportSuccess = (data) => {
    return {
        type: UPLOAD_FILE_EXPENSE_REPORT_SUCCESS,
        data: data,
    }
}

export const uploadFileContractorInvoiceSuccess = (data) => {
    return {
        type: UPLOAD_FILE_CONTRACTOR_INVOICE_SUCCESS,
        data: data,
    }
}

export const deleteFile = (token, id) => {
    return {
        type: DELETE_FILE,
        token: token,
        id: id,
    }
}

export const deleteFileSuccess = (data) => {
    return {
        type: DELETE_FILE_SUCCESS,
        data: data,
    }
}

export const setFilesState = (state) => {
    return {
        type: SET_FILE_STATE,
        payload: state,
    }
}
