// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {
    DELETE_JOB_OFFER,
    GET_JOB_OFFER,
    GET_JOB_OFFERS, JOB_OFFER_FICHE,
    UPDATE_APPLICATION_TIME,
    UPSERT_JOB_OFFER,
    GET_JOB_OFFERS_COUNT,
    JOB_OFFER_PROPOSAL,
    JOB_OFFER_CONTRACT,
    JOB_OFFER_CONTRACT_SIGN,
    JOB_OFFER_CONTRACT_PROPOSAL, JOB_OFFER_CONTRACT_END,
} from '../action-types'

// Import all api's
import {
    deleteJobOfferRequest,
    getJobOffersRequest,
    jobOfferFicheRequest, jobOfferProposalRequest, jobOfferContractRequest,
    updateApplicationTimeRequest, signJobOfferContractRequest, updateContractRequest, endContractRequest
} from '../api/JobOffer'

import {
    getJobOfferProposalSuccess,
    getJobOfferContractSuccess,
    getJobOffersCountSuccess,
    getJobOffersSuccess,
    getJobOfferSuccess, jobOfferFicheSuccess, updateApplicationFlowSuccess,
    updateApplicationTimeSuccess,
    upsertJobOfferSuccess, signJobOfferContractSuccess, updateContractSuccess, endContractSuccess
} from '../actions/JobOffer'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

function* getJobOffers({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getJobOffersRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getJobOffersSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getJobOffersCount({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getJobOffersRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getJobOffersCountSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* jobOfferFiche({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(jobOfferFicheRequest, token, query)
        if (response.status === 200) {
            yield put(jobOfferFicheSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getJobOffer({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getJobOffersRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getJobOfferSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteJobOffer({token, id, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(deleteJobOfferRequest, token, id)
        if (response.status === 200) {
            const newResponse = yield call(getJobOffersRequest, token, query)
            if (newResponse.status === 200) {
                if (newResponse.data.errors && newResponse.data.errors.length > 0) {
                    yield put(fetchError(newResponse.data))
                } else {
                    yield put(getJobOffersSuccess(newResponse.data))
                    yield put(fetchSuccess())
                }
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* upsertJobOffer({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getJobOffersRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(upsertJobOfferSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateApplicationTime({token, id, status}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateApplicationTimeRequest, token, id, status)
        if (response.status === 200) {
            yield put(updateApplicationTimeSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* jobOfferProposal({token}) {
    try {
        //yield put(fetchStart())
        const response = yield call(jobOfferProposalRequest, token)
        if (response.status === 200) {
            yield put(getJobOfferProposalSuccess(response.data))
            //yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* jobOfferContract({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(jobOfferContractRequest, token, id)
        if (response.status === 200) {
            yield put(getJobOfferContractSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* signJobOfferContract({token, data}) {
    try {
        yield put(fetchStart())
        const response = yield call(signJobOfferContractRequest, token, data)
        if (response.status === 200) {
            yield put(signJobOfferContractSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateContract({token, data}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateContractRequest, token, data)
        if (response.status === 200) {
            yield put(updateContractSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* endContract({token, data}) {
    try {
        yield put(fetchStart())
        const response = yield call(endContractRequest, token, data)
        if (response.status === 200) {
            yield put(endContractSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_JOB_OFFERS, getJobOffers)
    yield takeEvery(GET_JOB_OFFER, getJobOffer)
    yield takeEvery(DELETE_JOB_OFFER, deleteJobOffer)
    yield takeEvery(UPSERT_JOB_OFFER, upsertJobOffer)
    yield takeEvery(UPDATE_APPLICATION_TIME, updateApplicationTime)
    yield takeEvery(JOB_OFFER_FICHE, jobOfferFiche)
    yield takeEvery(GET_JOB_OFFERS_COUNT, getJobOffersCount)
    yield takeEvery(JOB_OFFER_PROPOSAL, jobOfferProposal)
    yield takeEvery(JOB_OFFER_CONTRACT, jobOfferContract)
    yield takeEvery(JOB_OFFER_CONTRACT_SIGN, signJobOfferContract)
    yield takeEvery(JOB_OFFER_CONTRACT_PROPOSAL, updateContract)
    yield takeEvery(JOB_OFFER_CONTRACT_END, endContract)
}