import {
    DELETE_JOB_OFFER,
    DELETE_JOB_OFFER_SUCCESS, FIND_TALENT_APPLICATION, FIND_TALENT_APPLICATION_SUCCESS,
    GET_JOB_OFFER,
    GET_JOB_OFFER_SUCCESS,
    GET_JOB_OFFERS,
    GET_JOB_OFFERS_SUCCESS,
    UPDATE_APPLICATION_TIME,
    UPDATE_APPLICATION_TIME_SUCCESS,
    UPSERT_JOB_OFFER,
    UPSERT_JOB_OFFER_SUCCESS,
    UPDATE_APPLICATION_FLOW,
    UPDATE_APPLICATION_FLOW_SUCCESS,
    JOB_OFFER_FICHE,
    JOB_OFFER_FICHE_SUCCESS,
    GET_JOB_OFFERS_COUNT,
    GET_JOB_OFFERS_COUNT_SUCCESS,
    JOB_OFFER_PROPOSAL,
    JOB_OFFER_PROPOSAL_SUCCESS,
    JOB_OFFER_CONTRACT,
    JOB_OFFER_CONTRACT_SUCCESS,
    JOB_OFFER_CONTRACT_SIGN,
    JOB_OFFER_CONTRACT_SIGN_SUCCESS,
    JUST_JOB_OFFER_PROPOSAL,
    JOB_OFFER_CONTRACT_PROPOSAL,
    JOB_OFFER_CONTRACT_PROPOSAL_SUCCESS,
    JOB_OFFER_CONTRACT_END,
    JOB_OFFER_CONTRACT_END_SUCCESS
} from '../action-types'

export const getJobOffers = (token, query) => {
    return {
        type: GET_JOB_OFFERS,
        token: token,
        query: query
    }
}

export const getJobOffersSuccess = (data) => {
    return {
        type: GET_JOB_OFFERS_SUCCESS,
        data: data?.data,
    }
}

export const getJobOffersCount = (token, query) => {
    return {
        type: GET_JOB_OFFERS_COUNT,
        token: token,
        query: query
    }
}

export const getJobOffersCountSuccess = (data) => {
    return {
        type: GET_JOB_OFFERS_COUNT_SUCCESS,
        data: data?.data,
    }
}

export const jobOfferFiche = (token, query) => {
    return {
        type: JOB_OFFER_FICHE,
        token: token,
        query: query
    }
}

export const jobOfferFicheSuccess = (data) => {
    return {
        type: JOB_OFFER_FICHE_SUCCESS,
        data: data,
    }
}
export const getJobOffer = (token, query) => {
    return {
        type: GET_JOB_OFFER,
        token: token,
        query: query
    }
}

export const getJobOfferSuccess = (data) => {
    return {
        type: GET_JOB_OFFER_SUCCESS,
        data: data?.data,
    }
}

export const deleteJobOffer = (token, id, query) => {
    return {
        type: DELETE_JOB_OFFER,
        token: token,
        id: id,
        query: query
    }
}

export const deleteJobOfferSuccess = (data) => {
    return {
        type: DELETE_JOB_OFFER_SUCCESS,
        data: data,
    }
}

export const upsertJobOffer = (token, query) => {
    return {
        type: UPSERT_JOB_OFFER,
        token: token,
        query: query
    }
}

export const upsertJobOfferSuccess = (data) => {
    return {
        type: UPSERT_JOB_OFFER_SUCCESS,
        data: data,
    }
}

export const updateApplicationTime = (token, id, status) => {
    return {
        type: UPDATE_APPLICATION_TIME,
        token: token,
        id: id,
        status: status
    }
}

export const updateApplicationTimeSuccess = (data) => {
    return {
        type: UPDATE_APPLICATION_TIME_SUCCESS,
        data: data,
    }
}

export const findTalentApplication =  (token, talentID) => {
    return {
        type: FIND_TALENT_APPLICATION,
        token: token,
        talentID: talentID
    }
}

export const findTalentApplicationSuccess = (data) => {
    return {
        type: FIND_TALENT_APPLICATION_SUCCESS,
        data: data,
    }
}

export const updateApplicationFlow = (token, formData) => {
    return {
        type: UPDATE_APPLICATION_FLOW,
        token: token,
        formData: formData
    }
}

export const updateApplicationFlowSuccess = (data) => {
    return {
        type: UPDATE_APPLICATION_FLOW_SUCCESS,
        data: data,
    }
}

export const getJobOfferProposal = (token) => {
    return {
        type: JOB_OFFER_PROPOSAL,
        token: token
    }
}

export const getJobOfferProposalSuccess = (data) => {
    return {
        type: JOB_OFFER_PROPOSAL_SUCCESS,
        data: data,
    }
}

export const getJobOfferContract = (token, id) => {
    return {
        type: JOB_OFFER_CONTRACT,
        token: token,
        id: id
    }
}

export const getJobOfferContractSuccess = (data) => {
    return {
        type: JOB_OFFER_CONTRACT_SUCCESS,
        data: data,
    }
}

export const signJobOfferContract = (token, data) => {
    return {
        type: JOB_OFFER_CONTRACT_SIGN,
        token: token,
        data: data
    }
}

export const signJobOfferContractSuccess = (data) => {
    return {
        type: JOB_OFFER_CONTRACT_SIGN_SUCCESS,
        data: data,
    }
}

export const justOfferProposal = (token, data) => {
    return {
        type: JUST_JOB_OFFER_PROPOSAL,
        token: token,
        data: data
    }
}

export const updateContract = (token, data) => {
    return {
        type: JOB_OFFER_CONTRACT_PROPOSAL,
        token: token,
        data: data
    }
}

export const updateContractSuccess = (data) => {
    return {
        type: JOB_OFFER_CONTRACT_PROPOSAL_SUCCESS,
        data: data,
    }
}

export const endContract = (token, data) => {
    return {
        type: JOB_OFFER_CONTRACT_END,
        token: token,
        data: data
    }
}

export const endContractSuccess = (data) => {
    return {
        type: JOB_OFFER_CONTRACT_END_SUCCESS,
        data: data,
    }
}